<template>
    <v-main>
        <div class="main-content">
        <router-view>
            
        </router-view>
    </div>
    </v-main>
</template>

<script>
    export default {
        name: 'SpinsBonuses',
        
    }
</script>

<style lang="scss" scoped>

</style>